import React from 'react'

import './Contact.css'

export default function Contact() { return (

<section id="contact" className="contact text-center">
<div className="container">

    <h2 className="align-center pb-3 mbr-fonts-style display-4">contact</h2>

    <div className="row">

        {/* -First column- */}
        <div className="col-md-8">

            {/* -Google map- */}
            <div id="map-container" className="z-depth-1-half map-container" style={{height: "300px"}}></div>

        </div>
        {/* -/First column- */}


        {/* -Second column- */}
        <div className="col-md-4">
            <ul className="text-xs-center">
                <li className="wow fadeInUp" data-wow-delay="0.2s"><i className="fa fa-map-marker fa-lg" aria-hidden="true" />
                    <p>Poughkeepsie, NY 12601, USA</p>
                </li>

                <li className="wow fadeInUp" data-wow-delay="0.3s"><i className="fa fa-phone fa-lg" aria-hidden="true" />
                    <p>845.827.5331</p>
                </li>

                <li className="wow fadeInUp" data-wow-delay="0.4s"><i className="fa fa-envelope fa-lg" aria-hidden="true" />
                    <p><a className="link-dull" href="mailto:advisor@hvadvisorygroup.com?Subject=Contact">advisor@hvadvisorygroup.com</a></p>
                </li>
            </ul>
        </div>
        {/* -/Second column- */}

    </div>

</div>
</section>

)}
