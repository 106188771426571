const ACTIONS = {

    TODO_ADD: 'TODO_ADD',
    TODO_TOGGLE: 'TODO_TOGGLE',
    TODO_DELETE: 'TODO_DELETE',

    GA_SIGNIN: 'GA_SIGNIN',
    GA_SIGNOUT: 'GA_SIGNOUT'

}

export default ACTIONS
