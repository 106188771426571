import React from 'react'

import './Community.css'

export default function Community() { return (

<main>

{/* -- Caption -- */}
<div className="caption">
    <div className="flex-center animated fadeInDown">
        <ul>
            <li>
                <h1 className="h1-responsive h1c">Community and Services</h1></li>
            <li>
                <h4 className="h4-responsive h1c">Connecting and Learning</h4>
            </li>
        </ul>
    </div>
</div>
{/* -- Caption -- */}


<section className="community">

    {/* -- Main layout -- */}
    <div className="container">
        {/* -- First row -- */}
        <div className="row">
            <div className="col-md-6">
                <h1 className="h1-responsive">Community</h1>
                <hr />
                Collaborte with HVAG team members.
            </div>

            {/* -- Main information -- */}
            <div className="col-md-6">
                <h4 className="h4-responsive">Events at HVAG</h4><br /><br />

                <table className="table table-sm">
                    <tbody>
                    <tr>
                        <td>Lunch + Learn</td>
                        <td>Join the team for lunch and to share knowledge and expertise.</td>
                    </tr>
                    <tr>
                        <td>Happy Hour</td>
                        <td>Join the team for drinks and to learn about - anything!</td>
                    </tr>
                    </tbody>
                </table>

            </div>
        </div>
        {/* -- /First row -- */}

        {/* -- Second row -- */}
        <div className="row">

            {/* -- First columnn -- */}
            <div className="col-md-6">

            </div>
            {/* -- First columnn -- */}

            {/* -- Second columnn -- */}
            <div className="col-md-2">

            </div>
            {/* -- Second columnn -- */}

            {/* -- Third columnn -- */}
            <div className="col-md-4">

            </div>
            {/* -- Third columnn -- */}

        </div>
        {/* -- /Second row -- */}
    </div>
    {/* -- /Main layout -- */}
</section>

</main>

)}
