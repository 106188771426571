import React from 'react'
import { Link } from 'react-router-dom'

import GoogleAuth from '../auth/GoogleAuth'

export default function Header() { return (
        
<header>
 
    {/* -- Navbar -- */}
    <nav className="navbar navbar-expand-lg navbar-dark special-color-dark fixed-top" role="navigation">

    <div className="container">

        {/* -- Navbar brand -- */}
        <Link className="navbar-brand" to="/home">Hudson Valley Advisory Group</Link>

        {/* -- Collapse button -- */}
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
            aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>

        {/* -- Collapsible content -- */}
        <div className="collapse navbar-collapse" id="navbarNav">

            {/* -- Links -- */}
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <Link className="nav-link" to="/home">Home <span className="sr-only">(current)</span></Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/community">Community</Link>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="https://www.hvadvisorygroup.com/hvag-blog/" target="_blank" rel="noopener noreferrer">
                        Blog
                    </a>
                </li>

                {/* -Dropdown- */}
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" to="">
                        Demos
                    </Link>
                    <div className="dropdown-menu dropdown-primary" aria-labelledby="navbarDropdownMenuLink">
                        <Link className="dropdown-item" to="/todos">Todo</Link>
                        <Link className="dropdown-item" to="/ttt">Tic-Tac-Toe</Link>
                    </div>
                </li>

                {/* -Signin- */}
                <GoogleAuth />

                {/*
                <li className="nav-item">
                    <Link className="nav-link disabled" to="#">Disabled</Link>
                </li>
                */}
                
            </ul>

         </div>

     </div>

 </nav>
 {/* -- /Navbar -- */}

</header>

)}
