import { combineReducers } from 'redux'

import todoReducer from './todoReducer'
import authReducer from './authReducer'

export default combineReducers({

    todos_R: todoReducer,
    auth_R: authReducer

})
