import React from 'react'
import ReactDOM from 'react-dom'

import Root from './Root'
import App from './App'

ReactDOM.render(

    // Provider info/config moved to Root to allow usage with testing
    <Root>
        <App />
    </Root>,

    document.getElementById('root')

)
