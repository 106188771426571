import React from 'react'

import Hero from './Hero'
import About from './About'
import Happening from './Happening'
import Contact from './Contact'

export default function Landing() { return (
    <div>
        <Hero />
        <About />
        <Happening />
        <Contact />
    </div>
)}
