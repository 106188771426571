import React from 'react'

const myStyle = {
    //background: "rgb(244, 244, 244)",
    backgroundColor: "#efebe9",
    paddingTop: "2rem"
}

export default function Happening() { return (

<section id="happening" className="text-center" style={myStyle}>

    <div className="container">
    
    {/* -- Heading -- */}
    <h2 className="align-center pb-3 mbr-fonts-style display-4">happening @hvag</h2>

    {/* -- Grid row -- */}
    <div className="row d-flex justify-content-center mb-4">

        {/* -- Grid column -- */}
        <div className="col-md-8">

            {/* -- Description -- */}
            <p className="lead"></p>

        </div>
        {/* -- Grid column -- */}

    </div>
    {/* -- Grid row -- */}

    {/* -- Grid row -- */}
    <div className="row">

        {/* -- Grid column -- */}
        <div className="col-md-4 mb-1">
            <i className="fa fa-arrows-alt fa-4x" style={{color: "rgb(8, 54, 68)"}}></i>
            <h4 className="my-4">Process Improvement</h4>
            <p className="lead">Identify and improve the performance of value streams. Optimize organizational structure to improve flow.</p>
        </div>
        {/* -- Grid column -- */}

        {/* -- Grid column -- */}
        <div className="col-md-4 mb-1">
            <i className="fa fa-cloud-upload-alt fa-4x" style={{color: "rgb(8, 54, 68)"}}></i>
            <h4 className="my-4">Cloud Services</h4>
            <p className="lead">Deploy infrastructure on Amazon Web Services, Microsoft Azure and Google Cloud Platform.</p>
        </div>
        {/* -- Grid column -- */}

        {/* -- Grid column -- */}
        <div className="col-md-4 mb-1">
            <i className="fa fa-globe fa-4x" style={{color: "rgb(8, 54, 68)"}}></i>
            <h4 className="my-4">Kubernetes & GitOps</h4>
            <p className="lead">Declaratively describe system state.  Automate system state changes.  Push code for system updates.</p>
        </div>
        {/* -- Grid column -- */}

    </div>
    {/* -- Grid row -- */}

    </div>
</section>

)}