import React from 'react'
import './Lost.css'

export default function Lost(props) {

    if (props.reason === '404') {
        return (
            <section className="hvaglayout lost-container mb-4">
                <h1>
                    4
                    <span><i class="far fa-dizzy"></i></span>
                    4
                </h1>
            </section>
        )
    }
    return (
        <section className="hvaglayout lost-container mb-4">
            <h1>{props.reason}</h1>
        </section>
    )
}
