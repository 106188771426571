import React from 'react'
import { useSelector } from 'react-redux'
import { Route } from 'react-router-dom'

import Lost from './Lost'

export default function ProtectedRoute({ component: ProtectedComponent, ...rest }) {

    const isSignedIn = useSelector(store => store.auth_R.isSignedIn)

    return (
        <Route { ...rest } render={ (props) => (
            isSignedIn === true
            ? <ProtectedComponent { ...props } />
            : <Lost {...props} reason="Signin Required" />
        )} />
    )
}
