import ACTIONS from '../actions/ActionTypes'

const initialState = require('./initialState.json')

export default (state = initialState, action) => {

    switch (action.type) {

        case ACTIONS.TODO_ADD:
            return { ...state, todos: [...state.todos, action.payload] }

        case ACTIONS.TODO_TOGGLE:
            return {
                ...state,
                todos: state.todos
                .map(todo => (todo.id === action.payload) ? { ...todo, isComplete: !todo.isComplete } : todo)
            }

        case ACTIONS.TODO_DELETE:
            return {
                ...state,
                todos: state.todos.filter(todo => (todo.id !== action.payload))
            }

        default:
            return state
    }

}
