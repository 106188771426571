import ACTIONS from '../actions/ActionTypes'

const /* Do Not Modify */ initialState = {
    isSignedIn: null,
    userId: null
}

export default (state = initialState, action) => {

    switch (action.type) {

        case ACTIONS.GA_SIGNIN:
            return { ...state, isSignedIn: true, userId: action.payload }

        case ACTIONS.GA_SIGNOUT:
            return { ...state, isSignedIn: false, userId: null }
    
        default:
            return state

    }

}
