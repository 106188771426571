import React from 'react'

const content = `
    We are an advisory group dedicated to helping small and medium businesses in the Hudson Valley achieve more.
    Sharing our professional experiences and hardened methodologies in order to create value for business leaders.
    Solving problems, accelerating the achievement of desired results, and fostering continuous improvement via
    knowledge sharing.
`

export default function About() { return (

<section id="about-us" className="text-center">
<div className="container">
    
    {/* -- Heading -- */}
    <h2 className="align-center pb-3 mbr-fonts-style display-4">about us</h2>

    {/* -- Grid row -- */}
    <div className="row d-flex justify-content-center mb-4">

        {/* -- Grid column -- */}
        <div className="col-md-8">

            {/* -- Description -- */}
            <h3 className="mbr-section-subtitle align-center mbr-light mbr-fonts-style display-5">
                {content}
            </h3>

        </div>
        {/* -- Grid column -- */}

    </div>
    {/* -- Grid row -- */}

</div>
</section>

)}
