import ACTIONS from './ActionTypes'

// Todo
export const addTodoAction = todo => ({
    type: ACTIONS.TODO_ADD,
    payload: todo
})

export const toggleTodoAction = todoId => ({
    type: ACTIONS.TODO_TOGGLE,
    payload: todoId
})

export const deleteTodoAction = todoId => ({
    type: ACTIONS.TODO_DELETE,
    payload: todoId
})


// GoogleAuthenticator
export const GASigninAction = (userId) => ({
    type: ACTIONS.GA_SIGNIN,
    payload: userId
})

export const GASignOutAction = () => ({
    type: ACTIONS.GA_SIGNOUT
})
