import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() { return (

<footer className="page-footer font-small special-color-dark pt-4">

    {/* -- Footer Links -- */}
    <div className="container mt-5 mb-4 text-center text-md-left">
        <div className="row mt-3">

            {/* -- First column -- */}
            <div className="col-md-6 col-lg-6 col-xl-3 mb-4">
                <h6 className="text-uppercase font-weight-bold">
                    <strong>Hudson Valley Advisory Group</strong>
                </h6>
                <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: "60px"}} />
                <p>Join the HVAG team</p>
            </div>
            {/* -- /First column -- */}

            {/* -- Second column -- */}
            {/* 
            <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 class="text-uppercase font-weight-bold">
                    <strong>Products</strong>
                </h6>
                <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                <p>
                    <a href="#!">MDBootstrap</a>
                </p>
                <p>
                    <a href="#!">MDWordPress</a>
                </p>
                <p>
                    <a href="#!">BrandFlow</a>
                </p>
                <p>
                    <a href="#!">Bootstrap Angular</a>
                </p>
            </div>
            */}
            {/* -- /Second column -- */}

            {/* -- Third column -- */}
            <div className="col-md-3 col-lg-3 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase font-weight-bold">
                    <strong>links</strong>
                </h6>
                <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: '60px' }} />
                <p>
                    <Link to="privacy">Privacy</Link>
                </p>
                <p>
                    <Link to="terms">Terms of Use</Link>
                </p>

                {/*
                <p>
                    <a href="#!">Help</a>
                </p>
                */}

            </div>
            {/* -- /Third column -- */}

            {/* -- Fourth column -- */}
            <div className="col-md-3 col-lg-3 col-xl-3">
                <h6 className="text-uppercase font-weight-bold">
                    <strong>Contact</strong>
                </h6>
                <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: '60px' }} />

                <p><i className="fa fa-home mr-3"></i> Poughkeepsie, NY 12601, US</p>

                <p><a className="link-dull" href="mailto:advisor@hvadvisorygroup.com?Subject=Contact">
                <i className="fa fa-envelope mr-3"></i>advisor@hvadvisorygroup.com</a></p>

                <p><i className="fa fa-phone mr-3"></i> 845.827.5331</p>
                
                <p>
                    {/* <i class="fa fa-print mr-3"></i> + 01 234 567 89 */} </p> 
            </div>
            {/* -- /Fourth column -- */}

        </div>
    </div>
    {/* -- /Footer Links -- */}

    {/* -- Copyright -- */}
    <div className="footer-copyright py-3 text-center">
        © 2019 Copyright:
        <Link to="home"> Hudson Valley Advisory Group</Link>
    </div>
    {/* -- /Copyright -- */}

</footer>

)}
