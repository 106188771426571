import React from 'react'

import './Hero.css'

export default function Hero() { return (

<section>
    <div className="jumbotron jumbotron-fluid text-center">
        <div className="container">
            <h1 className="display-3">hudson valley advisory group</h1>
            <p className="lead">serving the hudson valley and beyond</p>
        </div>
    </div>
</section>

)}
