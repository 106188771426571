/* @flow */

import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import Header from './components/common/Header'
import Footer from './components/common/Footer'
import Landing from './components/landing/Landing'
import Community from './components/Community'
import Privacy from './components/Privacy'
import Terms from './components/Terms'
import Lost from './components/Lost'
import ProtectedRoute from './components/ProtectedRoute'

import './styles.css'

// Lazy load site features
const Todos = lazy(() => import('./features/todo/Todos'))
const TTT = lazy(() => import('./features/ttt/TTT'))

export default function App() {
    return (
        <div>
            <Router>
                <Header />
                <Switch>
                    <Route exact path="/" component={Landing} />
                    <Route exact path="/home" component={Landing} />
                    <Route exact path="/community" component={Community} />
                    <Route exact path="/privacy" component={Privacy} />
                    <Route exact path="/terms" component={Terms} />
                    <Suspense fallback={<div></div>}>
                        <ProtectedRoute exact path='/todos' component={Todos} />
                        <ProtectedRoute exact path='/ttt' component={TTT} />
                    </Suspense>
                    <Route render={ (props) => <Lost {...props} reason="404" /> } />
                </Switch>
                <Footer />
            </Router>
        </div>      
    )
}
