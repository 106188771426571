// Create && export a component that returns a Provider tag w/ the Redux Store
// Move logic from index.js
//
// index.js and other components being tested can utilize Root.js

import React from 'react'
import { Provider } from 'react-redux'
import { createStore, compose } from 'redux'

import reducers from './reducers'

// Redux DevTools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducers, composeEnhancers())

// Provider makes the Redux store available to any nested components
// Typically done at top level, wrapping entire application

export default (props) => {
    return (
        <Provider store={store}>
            {props.children}
        </Provider>
    )
}

