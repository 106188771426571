import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { GASigninAction, GASignOutAction } from '../../actions/index'

export default function GoogleAuth() {

    const dispatch = useDispatch()
    const isSignedIn = useSelector(store => store.auth_R.isSignedIn)

    const gaSignIn = userId => dispatch(GASigninAction(userId))
    const gaSignOut = () => dispatch(GASignOutAction())

    let auth = undefined

    // On Auth Change, dispatch to Redux
    const onAuthChange = isSignedIn => {
        if (isSignedIn) {
            gaSignIn(auth.currentUser.get().getId())
        } else {
            gaSignOut()
        }
    }

    const googleConfig = () => {
        window.gapi.load('client:auth2', () => {
            window.gapi.client.init({
                clientId: '342596122069-v50p93d7rpmpo5v8sk44lce021g245uc.apps.googleusercontent.com',
                scope: 'openid'
            })
            .then(() => {
                auth = window.gapi.auth2.getAuthInstance()
                auth.isSignedIn.listen(onAuthChange)
                onAuthChange(auth.isSignedIn.get())
            })
        })
    }

    useEffect(() => {
        googleConfig()
    }, [])

    const renderAuthButton = () => {

        if (isSignedIn === null) {
            return null
        } else if (!isSignedIn) {
            return (
                <li className="nav-item">
                    <Link
                        className="nav-link"
                        to=""
                        onClick={window.gapi.auth2.getAuthInstance().signIn}>
                            Signin
                            <i className="fas fa-sign-in-alt" aria-hidden="true" />
                    </Link>
                </li>
            )
        } else {
            return (
                <li className="nav-item">
                    <Link
                        className="nav-link"
                        to=""
                        onClick={window.gapi.auth2.getAuthInstance().signOut}>
                            Signout
                            <i className="fas fa-sign-in-alt" aria-hidden="true"></i>
                    </Link>
                </li>
            )
        }
    }

    return renderAuthButton()
}
